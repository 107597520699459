import { message } from "antd";
import { loginAPI } from "api/common";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = React.createContext();

const fakeUserData = {
  id: 1,
  name: "Jhon Doe",
  avatar:
    "http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png",
  roles: ["USER", "ADMIN"],
};

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const signIn = async (params) => {
    console.log(params, "sign in form Props");
    setUser(fakeUserData);
    const payload = {
      email: params.email,
      password: params.password,
    };
    try {
      const res = await loginAPI(payload);
      console.log("res", res);
      if (res?.statusCode === 200) {
        localStorage.setItem("token", res?.data?.access_token);
        setLoggedIn(true);
        navigate("/", { replace: true });
      } else {
        message.error(res?.message || "Something went wrong");
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const signUp = (params) => {
    console.log(params, "sign up form Props");
    setUser(fakeUserData);
    setLoggedIn(true);
    navigate("/", { replace: true });
  };

  const logOut = () => {
    localStorage.removeItem("token");
    setUser(null);
    setLoggedIn(false);
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
