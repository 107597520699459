import React, { useState } from "react";
import { Input } from "antd";

import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const SearchInput = (props) => {
  const [searchBox, setSearchBox] = useState();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const { getInputValue, goToSearchPage } = props;
  const [locationInput, setLocationInput] = useState("");
  const handleOnChange = (event) => {
    if (event.which === "13") {
      event.preventDefault();
      event.stopPropagation();
    }
    setLocationInput(event.target.value);
    getInputValue(event.target.value);
  };

  const handleOnPressEnter = (event) => {
    if (event.which === 13) {
      goToSearchPage(encodeURIComponent(locationInput));
    }
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <div className="map_autocomplete">
      {isLoaded && (
        // <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
        <Input
          type="text"
          defaultValue=""
          value={locationInput || ""}
          placeholder="Search “Thailand, Asia”"
          size="large"
          onChange={handleOnChange}
          onPressEnter={handleOnPressEnter}
        />
        // </StandaloneSearchBox>
      )}
    </div>
  );
};

const MapAutoComplete = (props) => {
  const { updateValue, goToSearchPage } = props;
  return (
    <SearchInput getInputValue={updateValue} goToSearchPage={goToSearchPage} />
  );
};

export default MapAutoComplete;
