import { apiRequest } from ".";

export const getItinerariesAPI = (data) => {
  return apiRequest({
    method: "get",
    url: `itinary`,
    queryParams: data,
  });
};
export const getItinerarieDetailsAPI = (id) => {
  return apiRequest({
    method: "get",
    url: `itinary?id=${id}`,
    queryParams: {},
  });
};
export const getItineryMetaDataAPI = (id) => {
  return apiRequest({
    method: "get",
    url: `itinary/view/meta?shopify_id=${id}`,
    queryParams: {},
  });
};
export const getPDF = (id) => {
  return apiRequest({
    method: "get",
    url: `itinary/view/signed?id=${id}`,
    queryParams: {},
  });
};

export const loginAPI = (data) => {
  return apiRequest({
    method: "post",
    url: `user/auth/login`,
    data: data,
  });
};

export const getUserDetailAPI = (data) => {
  return apiRequest({
    method: "get",
    url: `user/profile`,
    data: data,
  });
};

export const profileUpdateAPI = (data) => {
  return apiRequest({
    method: "put",
    url: `user/profile`,
    data: data,
  });
};

export const getFavItinerariesAPI = (data) => {
  return apiRequest({
    method: "get",
    url: `itinary/favourite`,
    queryParams: data,
  });
};

export const markFavouriteAPI = (data) => {
  return apiRequest({
    method: "post",
    url: `itinary/favourite`,
    data: data,
  });
};
export const markUnfavouriteAPI = (id) => {
  return apiRequest({
    method: "delete",
    url: `itinary/favourite/${id}`,
  });
};
export const getFiltersAPI = () => {
  return apiRequest({
    method: "get",
    url: `itinary/filters`,
    queryParams: {},
  });
};
export const getItinerariesFilterAPI = (queryParam) => {
  let _url = Object.keys(queryParam)
    .map(function (k) {
      if (true) {
        return encodeURIComponent(k) + `=${queryParam[k].join(",")}`;
      } else {
        return encodeURIComponent(k) + "=" + encodeURIComponent(queryParam[k]);
      }
    })
    .join("&");
  return apiRequest({
    method: "get",
    url: `itinary?${_url}`,
    queryParams: {},
  });
};
export const sendOTPforPWDApi = (data) => {
  return apiRequest({
    method: "post",
    url: `user/auth/forgot-password`,
    data: data,
  });
};
export const verifyOTPforPWDApi = (data) => {
  return apiRequest({
    method: "post",
    url: `user/auth/verify`,
    data: data,
  });
};
