import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { AuthContext } from "context/AuthProvider";
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  USER_PROFILE_PAGE,
  FAVOURITE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
} from "settings/constant";

const MobileMenu = ({ className, isLogin }) => {
  let navigate = useNavigate();
  const { logOut } = useContext(AuthContext);
  // auth context
  function handleLogout() {
    logOut();
    navigate("/", { replace: true });
  }

  return (
    <Menu className={className}>
      {/* <Menu.Item key="0">
        <NavLink to={HOME_PAGE}>Hotels</NavLink>
      </Menu.Item> */}

      <Menu.Item key="2">
        <NavLink to={LISTING_POSTS_PAGE}>All Itineraries</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={FAVOURITE_PAGE}>Favourite Itineraries</NavLink>
      </Menu.Item>
      {/* {loggedIn && (
        <Menu.Item key="3">
          <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
      )} */}
      {isLogin ? (
        <>
          <Menu.Item key="1">
            <NavLink to={USER_PROFILE_PAGE}>My Profile</NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <button onClick={handleLogout}>Log Out</button>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );
};

export default MobileMenu;
