/**
 * constant file for whole application
 */

const constant = {
  ENV: 1, // DEV:1, STAGE:2, PROD:3
  API_URL: {
    DEV: "https://api.diytinerary.com/api/",
    PROD: "https://api.diytinerary.com/api/",
  },
  RESPONSE_CODE: {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  },
};
export default constant;
